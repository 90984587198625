import {
  DateRangePickerContainer,
  DateRangePickerContainerProps,
} from "src/ui/DateRangePicker/DateRangePickerContainer"

import { DateField } from "./DateField"

export type TDateRange = {
  startDate: Date | null
  endDate: Date | null
}

export type DateRangePickerProps = Omit<
  DateRangePickerContainerProps,
  "renderButton" | "behavior"
>

export function DateRangePicker({
  onButtonClick,
  disabled,
  ...dateRangePickerProps
}: DateRangePickerProps & { onButtonClick?: () => void; disabled?: boolean }) {
  return (
    <DateRangePickerContainer
      {...dateRangePickerProps}
      behavior="dateRange"
      renderButton={({ onClick, state }) => {
        return (
          <DateField
            startDate={state.startDate}
            endDate={state.endDate}
            onClick={() => {
              onClick()
              onButtonClick?.()
            }}
            disabled={disabled}
          />
        )
      }}
    />
  )
}
